<template>
  <v-container
    id="promotional-codes"
    fluid
    tag="section"
    class="container-full-size"
  >
    <v-data-table
      :headers="headersFiltered"
      :items="codesFiltered"
      sort-by="date"
      :sort-desc="true"
      :search="search"
      :no-data-text="str['promotional_codes_empty']"
      :no-results-text="str['promotional_codes_empty']"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
          v-if="showManualRegisters || showReturnRegisters"
          flat
          class="toolbar-adjust table-toolbar-no-padding-sides pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-1"
            >
              <v-tabs
                v-model="tabActive"
                background-color="transparent"
                color="secondary"
                style="margin-top: 0;"
                grow
                show-arrows
              >
                <v-tab
                  v-for="tab in tabs"
                  :key="tab.value"
                >
                  {{ tab.label }}
                </v-tab>
              </v-tabs>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-toolbar
          flat
        >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="str['search']"
            single-line
            hide-details
          />
          <v-spacer />
          <v-btn
            v-if="editPermission && (!showReturnRegisters || (showReturnRegisters && tabs[tabActive].value !== 'return_registers'))"
            color="success"
            dark
            class="mb-2"
            @click="openNewCode()"
          >
            {{ str['add'] }}
          </v-btn>
          <v-btn
            v-if="editPermission && showReturnRegisters && tabs[tabActive].value === 'return_registers'"
            color="blue"
            dark
            class="mb-2"
            @click="openNewReturnCode(true)"
            style="margin-right: 10px;"
          >
            {{ str['add_manual_return_register'] }}
          </v-btn>
          <v-btn
            v-if="editPermission && showReturnRegisters && tabs[tabActive].value === 'return_registers'"
            color="success"
            dark
            class="mb-2"
            @click="openNewReturnCode()"
          >
            {{ str['add_subscription_return_register'] }}
          </v-btn>
          <v-dialog
            v-model="dialogCode"
            persistent
            max-width="750px"
          >
            <v-card>
              <v-card-title>
                <span class="headline">
                  {{ str['new_promotional_code'] }}
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      v-for="item in data"
                      :key="item.id"
                      cols="12"
                    >
                      <div class="d-flex align-center">
                        <v-text-field
                          v-if="item.type === 'input'"
                          v-model="item.value"
                          :label="item.title"
                          :disabled="item.disabled ? true : false"
                          hide-details
                        />
                        <v-btn
                          v-if="item.id === 'code'"
                          color="primary"
                          @click="generateNewCode(item)"
                          class="ml-2"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </div>
                      <v-autocomplete
                        v-if="item.type === 'select'"
                        v-model="item.value"
                        :label="item.title"
                        item-text="label"
                        item-value="value"
                        :items="item.items"
                        :no-data-text="str['no_data']"
                        :multiple="item.multiple"
                        hide-details
                      />
                      <div
                        v-if="item.type === 'chips'"
                      >
                        <h5>
                          {{ str['promo_code_add_emails'] }}
                        </h5>
                        <div
                          class="row-flex-align mb-1"
                        >
                          <v-text-field
                            v-model="item.input"
                            @keyup.enter="addChip(item)"
                            :placeholder="str['add_email']"
                            single-line
                            hide-details
                          />
                          <v-icon
                            @click="addChip(item)"
                            color="success"
                            class="ml-3"
                          >
                            mdi-plus-circle
                          </v-icon>
                        </div>
                        <div class="row-flex-align mt-2">
                          <v-chip
                            v-for="(chip, index) in item.value"
                            :key="index"
                            @click="removeChip(index, item)"
                            @click:close="removeChip(index, item)"
                            close
                            class="mr-1"
                          >
                            {{ chip }}
                          </v-chip>
                        </div>
                      </div>
                      <v-checkbox
                        v-if="item.type === 'checkbox'"
                        v-model="item.value"
                        :label="item.title"
                        class="form-field-checkbox"
                        hide-details
                        @change="item.callback"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn
                  color="default"
                  @click="closeCode"
                >
                  {{ str['cancel'] }}
                </v-btn>
                <v-btn
                  color="success"
                  @click="saveCode"
                >
                  {{ str['save'] }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-toolbar
          v-if="!showManualRegisters || ((showManualRegisters && tabs[tabActive].value !== 'manual_registers') && (showReturnRegisters && tabs[tabActive].value !== 'return_registers'))"
          flat
          height="40px"
        >
          <div class="pb-3">
            <v-checkbox
              v-model="includeExpired"
              :label="str['include_expired']"
              class="form-field-checkbox"
              @change="getCodes()"
              style="margin: 0;"
              hide-details
            />
          </div>
        </v-toolbar>
      </template>
      <template v-slot:item.type="{ item }">
        {{ typesDict[item.type] ? typesDict[item.type] : '-' }}
      </template>
      <template v-slot:item.value="{ item }">
        {{ convertPrice && typesConvertValue && typesConvertValue[item.type] ? (item.value / 100) : item.value }}
      </template>
      <template v-slot:item.packages="{ item }">
        <v-icon
          v-if="item.packages && item.packages.length"
          color="info"
          dark
          class="cursor-hover ml-1"
          @click.stop="showPacks(item.packages)"
        >
          mdi-information
        </v-icon>
        <span
          v-if="!item.packages || (item.packages && !item.packages.length)"
        >
          -
        </span>
      </template>
      <template v-slot:item.employee="{ item }">
        {{ item.employee ? item.employee : '-' }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="editPermission && item.status === 0"
          small
          class="mr-0"
          color="error"
          @click.stop="deleteCode(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} {{ str['of'] }} {{ itemsLength }}
      </template>
    </v-data-table>

    <v-dialog
      v-if="showReturnRegisters"
      v-model="dialogSubscriptionReturnRegister"
      persistent
      max-width="550px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['want_create_subscription_return_register_code'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
              >
                <v-text-field
                  v-model="subscriptionReturnRegisterUrl"
                  :label="str['link_url']"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="default"
            @click="closeSubscriptionReturnRegister"
          >
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            color="success"
            @click="confirmSubscriptionReturnRegister"
          >
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import Api from '@/services/Api'
  import Utils from '@/services/Utils'
  import { v4 as uuidv4 } from 'uuid';

  export default {
    data () {
      const user = Utils.getUser()
      const headers = [
        { text: window.strings['date'], value: 'date', align: 'left', width: 140 },
        { text: window.strings['code'], value: 'code', align: 'left', width: 100 },
      ]
      const manualRegistersHeaders = [
        { text: window.strings['date'], value: 'date', align: 'left', width: 110 },
        { text: window.strings['code'], value: 'code', align: 'left', width: 100 },
      ]
      const returnRegistersHeaders = [
        { text: window.strings['date'], value: 'date', align: 'left', width: 110 },
        { text: window.strings['code'], value: 'code', align: 'left', width: 100 },
      ]
      const fields = [{
        type: 'input',
        id: 'code',
        title: window.strings['code'],
        value: '',
        visible: true,
        number: false,
      }]
      const editPermission = Utils.hasPermission('promo_codes_edit') ? true : false
      const viewEmployee = Utils.hasPermission('promo_codes_view_employee') ? true : false
      const hasPacks = user && user.configurations && user.configurations.promotional_codes && user.configurations.promotional_codes.has_packs ? true : false
      const hasEmails = user && user.configurations && user.configurations.promotional_codes && user.configurations.promotional_codes.has_emails ? true : false
      const showManualRegisters = user && user.configurations && user.configurations.promotional_codes && user.configurations.promotional_codes.show_manual_registers ? true : false
      const showReturnRegisters = user && user.configurations && user.configurations.promotional_codes && user.configurations.promotional_codes.show_return_registers ? true : false

      if (!window.useHotmart) {
        headers.push({ text: window.strings['type'], value: 'type', align: 'center', width: 140 })
        headers.push({ text: window.strings['value'], value: 'value', align: 'center', width: 100 })
        fields.push({
          type: 'select',
          id: 'type',
          title: window.strings['type'],
          items: Utils.getPromoCodesPlanTypes(),
          value: '',
          visible: true,
          number: true,
          multiple: false,
        })
        fields.push({
          type: 'input',
          id: 'value',
          title: window.strings['value'],
          value: '',
          visible: true,
          number: true,
        })
      }

      if (window.useHotmart) {
        headers.push({ text: window.strings['link_url'], value: 'aux_id', align: 'center', width: 120 })
        returnRegistersHeaders.push({ text: window.strings['link_url'], value: 'aux_id', align: 'center', width: 120 })
        fields.push({
          type: 'select',
          id: 'source',
          title: window.strings['source'],
          items: Utils.getPromoCodesSources(),
          value: '',
          visible: true,
          number: false,
          multiple: false,
        })
        fields.push({
          type: 'input',
          id: 'aux_id',
          title: window.strings['link_url'],
          value: '',
          visible: true,
          number: false,
        })
      }

      if (hasPacks) {
        headers.push({ text: window.strings['packs'], value: 'packages', sortable: false, align: 'center', width: 80 })
        fields.push({
          type: 'select',
          id: 'packages',
          title: window.strings['packs'],
          items: [],
          value: '',
          visible: true,
          number: false,
          multiple: true,
        })
      }

      headers.push({ text: window.strings['validity_min'], value: 'val_mins', align: 'center', width: 140 })
      headers.push({ text: window.strings['valid_until'], value: 'val_date', align: 'center', width: 140 })
      headers.push({ text: window.strings['number_times_used'], value: 'uses_cnt', align: 'center', width: 140 })
      headers.push({ text: window.strings['number_times_can_be_used'], value: 'uses_max', align: 'center', width: 210 })
      manualRegistersHeaders.push({ text: window.strings['number_times_used'], value: 'uses_cnt', align: 'center', width: 140 })
      manualRegistersHeaders.push({ text: window.strings['number_times_can_be_used'], value: 'uses_max', align: 'center', width: 140 })
      returnRegistersHeaders.push({ text: window.strings['number_times_used'], value: 'uses_cnt', align: 'center', width: 140 })
      returnRegistersHeaders.push({ text: window.strings['number_times_can_be_used'], value: 'uses_max', align: 'center', width: 210 })
      returnRegistersHeaders.push({ text: window.strings['type'], value: 'type', align: 'center', width: 140 })

      if (user && user.configurations && user.configurations.promotional_codes && user.configurations.promotional_codes.show_employee && viewEmployee) {
        headers.push({ text: window.strings['employee'], value: 'employee', align: 'center', width: 120 })
        manualRegistersHeaders.push({ text: window.strings['employee'], value: 'employee', align: 'center', width: 120 })
        returnRegistersHeaders.push({ text: window.strings['employee'], value: 'employee', align: 'center', width: 120 })
      }

      if (user && !Utils.isEmployee(user.type)) {
        headers.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 80 })
        manualRegistersHeaders.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 80 })
        returnRegistersHeaders.push({ text: window.strings['actions'], value: 'actions', sortable: false, align: 'center', width: 80 })
      }

      fields.push({
        type: 'input',
        id: 'val_mins',
        title: window.strings['validity_min'],
        value: '',
        visible: true,
        number: true,
      })
      fields.push({
        type: 'input',
        id: 'uses_max',
        title: window.strings['number_times_can_be_used'],
        value: '',
        visible: true,
        number: true,
      })

      if (user && user.configurations && user.configurations.promotional_codes && user.configurations.promotional_codes.has_discount_following_payments) {
        fields.push({
          type: 'checkbox',
          id: 'partnership_reg',
          title: window.strings['apply_discount_following_payments'],
          value: '',
          visible: true,
          number: false,
          callback: this.changeApplyDiscountFollowingDiscounts
        })
      }

      if (hasEmails) {
        fields.push({
          type: 'chips',
          id: 'emails',
          title: window.strings['emails'],
          items: [],
          value: '',
          visible: true,
          number: false,
          multiple: true,
        })
      }

      const tabs = [{
        label: window.strings['promotional_codes'],
        value: 'promotional_codes'
      }]
      if (showManualRegisters) {
        tabs.push({
          label: window.strings['manual_registers'],
          value: 'manual_registers'
        })
      }
      if (showReturnRegisters) {
        tabs.push({
          label: window.strings['return_registers'],
          value: 'return_registers'
        })
      }

      const paymentTypes = Utils.getPaymentTypes()
      const paymentType = paymentTypes ? paymentTypes.find(function (p) {
        return p.value === window.gatewayType
      }) : null

      return {
        str: window.strings,
        user: user,
        manualRegistersHeaders: manualRegistersHeaders,
        returnRegistersHeaders: returnRegistersHeaders,
        editPermission: editPermission,
        showManualRegisters: showManualRegisters,
        showReturnRegisters: showReturnRegisters,
        hasPacks: hasPacks,
        headers: headers,
        data: fields,
        tabs: tabs,
        convertPrice: paymentType && paymentType.convert_promotional_code_price ? true : false,
        manualRegistersType: Utils.getPaymentTypeValue('manual'),
        manualReturnRegistersType: Utils.getPaymentTypeValue('manual_return'),
        subscriptionReturnRegistersType: Utils.getPaymentTypeValue('subscription_return'),
        typesDict: this.getTypesDict(),
        typesConvertValue: {},
        search: '',
        codes: [],
        tabActive: 0,
        includeExpired: false,
        dialogCode: false,
        dialogSubscriptionReturnRegister: false,
        subscriptionReturnRegisterUrl: null,
        packsDict: {},
      }
    },
    watch: {
      dialogCode (val) {
        const dialog = document.getElementsByClassName('v-dialog')
        if (dialog && dialog[0]) {
          dialog[0].scrollTo(0, 0)
        }
        val || this.closeCode()
      },
    },
    computed: {
      headersFiltered() {
        if (this.showManualRegisters && this.tabs[this.tabActive].value === 'manual_registers') {
          return this.manualRegistersHeaders
        }
        if (this.showReturnRegisters && this.tabs[this.tabActive].value === 'return_registers') {
          return this.returnRegistersHeaders
        }
        return this.headers
      },
      codesFiltered() {
        const self = this;
        let codes = this.codes
        if (this.showManualRegisters) {
          if (this.tabs[this.tabActive].value === 'promotional_codes') {
            codes = codes.filter(function (item) {
              return item.type !== self.manualRegistersType && item.type !== self.manualReturnRegistersType && item.type !== self.subscriptionReturnRegistersType
            })
          } else if (this.tabs[this.tabActive].value === 'return_registers') {
            codes = codes.filter(function (item) {
              return item.type === self.manualReturnRegistersType || item.type === self.subscriptionReturnRegistersType
            })
          } else {
            codes = codes.filter(function (item) {
              return item.type === self.manualRegistersType
            })
          }
        }
        return codes
      },
    },
    beforeMount: function () {
      if (!this.user) {
        return false
      }
      this.setTypesConvertValue()
      this.getCodes()
      this.getPacks()
    },
    methods: {
      getTypesDict: function () {
        const obj = {}
        const items = Utils.getPromoCodesPlanTypes()
        if (items) {
          for (let i = 0; i < items.length; i++) {
            obj[items[i].value] = items[i].label
          }
        }
        return obj
      },
      setTypesConvertValue: function () {
        const obj = {}
        if (this.convertPrice) {
          const types = Utils.getPromoCodesPlanTypes()
          if (types) {
            types.forEach(function (l) {
              obj[l.value] = l.convert_price
            })
          }
          this.typesConvertValue = obj
        }
      },
      getCodes: function () {
        const self = this
        const includeExtraHeaders = ['status', 'type']
        const excludeHeaders = ['actions']
        let fields = []
        this.headers.forEach(function (item) {
          if (excludeHeaders.indexOf(item.value) === -1) {
            fields.push(item.value)
          }
        })
        includeExtraHeaders.forEach(function (item) {
          if (fields.indexOf(item) === -1) {
            fields.push(item)
          }
        })
        this.$isLoading(true)
        Api.getCodes({
          fields: fields,
          includeExpired: this.includeExpired
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].date = Utils.getFormatDateHour(response.data[i].date, true, 'YYYY-MM-DD HH:mm')
              if (self.hasPacks) {
                response.data[i].packages = response.data[i].packages && typeof response.data[i].packages === 'string' ? JSON.parse(response.data[i].packages) : []
              }
            }
            self.codes = response.data
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getPacks: function () {
        const self = this
        if (this.hasPacks) {
          const statusList = Utils.getPaymentsPacksStatus()
          const successStatus = statusList ? statusList.find(function (s) {
            return s.status === 'active'
          }) : null
          Api.getPaymentsPacks({
            fields: ['id', 'name', 'type', 'price', 'status']
          }, function (response) {
            if (response.success) {
              const dict = {}
              response.data.forEach(function (d) {
                dict[d.id] = d.name
              })
              self.packsDict = dict
              for (let i = 0; i < self.data.length; i++) {
                if (self.data[i].id === 'packages') {
                  self.data[i].items = response.data.filter(function (d) {
                    return d.status === successStatus.value
                  }).map(a => ({label: (a.name + ' (' + (a.price / 100) + '€ - ' + self.getPackType(a.type) + ')'), value: a.id}))
                  break
                }
              }
            }
          })
        }
      },
      getPackType: function (value) {
        if (value === '1#month') {
          return window.strings['monthly']
        }
        if (value === '1#month') {
          return window.strings['monthly']
        }
        if (value === '3#month') {
          return window.strings['quarterly']
        }
        if (value === '6#month') {
          return window.strings['semiannual']
        }
        if (value === '12#month' || value === '1#year') {
          return window.strings['annual']
        }
        if (value) {
          const split = value.split('#')
          const type = split[1]
          const quantity = parseInt(split[0])
          let key = ''
          if (type === 'day') {
            key = quantity > 1 ? 'days' : 'day'
          }
          if (type === 'week') {
            key = quantity > 1 ? 'weeks' : 'week'
          }
          if (type === 'month') {
            key = quantity > 1 ? 'months' : 'month'
          }
          if (type === 'year') {
            key = quantity > 1 ? 'years' : 'year'
          }
          return quantity + ' ' + (key ? window.strings[key] : type)
        }
      },
      openNewCode () {
        const self = this
        if (this.showManualRegisters && this.tabs[this.tabActive].value === 'manual_registers') {
          return this.$confirm(
            window.strings['want_create_manual_register_code'],
            '',
            'success',
            Utils.getAlertOptions(true)
          ).then(() => {
            self.$isLoading(true)
            Api.newCode({
              type: self.manualRegistersType
            }, function (response) {
              self.$isLoading(false)
              if (response.success) {
                response.data.date = Utils.getFormatDateHour(response.data.date, true, 'YYYY-MM-DD HH:mm')
                self.codes.push(response.data)
              } else {
                self.$alert(
                  response.message,
                  '',
                  'warning',
                  Utils.getAlertOptions()
                )
              }
            })
          }).catch(() => { })
        }
        this.setData()
        this.dialogCode = true
      },
      openNewReturnCode (manual) {
        const self = this
        if (this.showReturnRegisters && this.tabs[this.tabActive].value === 'return_registers') {
          if (manual) {
            this.$confirm(
              window.strings['want_create_manual_return_register_code'],
              '',
              'success',
              Utils.getAlertOptions(true)
            ).then(() => {
              self.$isLoading(true)
              Api.newCode({
                type: self.manualReturnRegistersType
              }, function (response) {
                self.$isLoading(false)
                if (response.success) {
                  response.data.date = Utils.getFormatDateHour(response.data.date, true, 'YYYY-MM-DD HH:mm')
                  self.codes.push(response.data)
                } else {
                  self.$alert(
                    response.message,
                    '',
                    'warning',
                    Utils.getAlertOptions()
                  )
                }
              })
            }).catch(() => { })
          } else {
            this.openSubscriptionReturnRegister()
          }
        }
      },
      openSubscriptionReturnRegister: function () {
        this.subscriptionReturnRegisterUrl = ''
        this.dialogSubscriptionReturnRegister = true
      },
      closeSubscriptionReturnRegister: function () {
        this.dialogSubscriptionReturnRegister = false
        this.subscriptionReturnRegisterUrl = null
      },
      confirmSubscriptionReturnRegister: function () {
        const self = this
        const url = this.subscriptionReturnRegisterUrl ? this.subscriptionReturnRegisterUrl.trim() : null
        this.$isLoading(true)
        Api.newCode({
          type: this.subscriptionReturnRegistersType,
          aux_id: url ? url : null
        }, function (response) {
          self.$isLoading(false)
          if (response.success) {
            response.data.date = Utils.getFormatDateHour(response.data.date, true, 'YYYY-MM-DD HH:mm')
            self.codes.push(response.data)
            self.closeSubscriptionReturnRegister()
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      getData: function () {
        const data = {}
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].type === 'checkbox') {
            data[this.data[i].id] = this.data[i].value ? 1 : 0
            continue
          }
          if (this.data[i].id === 'value' && this.convertPrice && this.typesConvertValue && this.typesConvertValue[data.type]) {
            data[this.data[i].id] = parseFloat(this.data[i].value) * 100
            continue
          }
          if (this.data[i].id === 'packages' || this.data[i].id === 'emails') {
            data[this.data[i].id] = this.data[i].value && this.data[i].value.length ? JSON.stringify(this.data[i].value) : ''
            continue
          }
          data[this.data[i].id] = this.data[i].number ? parseFloat(this.data[i].value) : this.data[i].value
        }
        return data
      },
      setData: function () {
        for (let i = 0; i < this.data.length; i++) {
          this.data[i].value = this.data[i].type === 'chips' ? [] : ''
          if (this.data[i].id === 'code')
            this.data[i].value = this.generateCode();
        }
      },
      closeCode () {
        this.dialogCode = false
      },
      saveCode () {
        const self = this
        this.$isLoading(true)
        const data = this.getData()
        Api.newCode(data, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.closeCode()
            response.data.date = Utils.getFormatDateHour(response.data.date, true, 'YYYY-MM-DD HH:mm')
            self.codes.push(response.data)
          } else {
            self.$alert(
              response.message,
              '',
              'warning',
              Utils.getAlertOptions()
            )
          }
        })
      },
      deleteCode (item) {
        const self = this
        const code = item.code
        const data = {
          code: code,
          status: 1,
        }
        this.$confirm(
          window.strings['are_you_sure_delete'],
          '',
          'warning',
          Utils.getAlertOptions(true, true)
        ).then(() => {
          self.$isLoading(true)
          Api.updateCode(data, function (response) {
            self.$isLoading(false)
            if (response.success) {
              for (let i = 0; i < self.codes.length; i++) {
                if (self.codes[i].code === code) {
                  self.codes.splice(i, 1)
                  break
                }
              }
            } else {
              self.$alert(
                response.message,
                '',
                'warning',
                Utils.getAlertOptions()
              )
            }
          })
        }).catch(() => { })
      },
      generateCode () {
        const uuid = uuidv4()
        const generatedCode = uuid.substr(0, 6).toUpperCase()
        return generatedCode
      },
      generateNewCode (item) {
        item.value = this.generateCode()
      },
      changeApplyDiscountFollowingDiscounts (value) {
        for (let i = 0; i < this.data.length; i++) {
          if (this.data[i].id === 'uses_max') {
            this.data[i].value = value ? '1' : ''
            this.data[i].disabled = value ? true : false
            break
          }
        }
      },
      showPacks: function (packs) {
        const self = this
        let info = ''
        packs.forEach(function (p) {
          info += self.packsDict[p] + '\n'
        })
        this.$alert(
          info,
          '',
          Utils.getAlertOptions()
        )
      },
      addChip(item) {
        const value = item.input ? item.input.trim() : ''
        if (value && !item.value.includes(value)) {
          item.value.push(value)
          item.input = ''
        }
      },
      removeChip(index, item) {
        item.value.splice(index, 1)
      },
    },
  }
</script>
